import React from "react";
import Navbar from "../Navbar";
import Project from "./projectheader/projectheader";
import Gallery from "./gallery/gallery";

import Footer from "../Footer";

function ProjectPage() {
  return (
    <div>
      <Navbar />
      <Project />
      <Gallery />
      <Footer />
    </div>
  );
}

export default ProjectPage;
