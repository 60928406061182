import React from "react";
import Navbar from '../Navbar';
import ReviewHeader from "./reviewheader/reviewheader";
import Review from "./reviewssection/reviewssection";
import Footer from "../Footer"; 

function Reviews() {
  return (
    <div>
      <Navbar />
      <ReviewHeader />
      <Review/>
      <Footer />
    </div>
  );
}

export default Reviews;
