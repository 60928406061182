import React from "react";
import Navbar from '../Navbar';
import HomeHeader from "./Header/Header";
import CombinedSection from "./Combined/CombinedSection";
import Footer from "../Footer"; 

function Home() {
  return (
    <div>
      <Navbar />
      <HomeHeader /> 
      <CombinedSection />
      <Footer />
    </div>
  );
}

export default Home;
