import React from "react";
import "./projectheader.css";

function ProjectHeader() {
  return (
    <header className="Project-header">
      <div className="project-static-background">
        <div className="project-content">
          <h1>Projects</h1>
          <p>With an abundance of projects and experience under our belts, we are proud to show you some of the many successfully completed projects we have conducted.​</p>
        </div>
      </div>
    </header>
  );
}
export default ProjectHeader;
