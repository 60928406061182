import React from "react";
import AboutUsHeader from "./AboutHeader/AboutUsHeader";
import AboutSec from "./AboutSection/AboutSec";
import Navbar from "../Navbar";
import Footer from "../Footer";

function AboutUs() {
  return (
    <div>
      <Navbar />
      <AboutUsHeader />
      <AboutSec />
      <Footer />
    </div>
  );
}

export default AboutUs;
