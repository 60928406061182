import React from "react";
import Navbar from '../Navbar';
import ServiceHeader from "./serviceheader/ServiceHeader";
import ServiceSection from "./servicesection/servicesection";
import Footer from "../Footer"; 

function Service() {
  return (
    <div>
      <Navbar />
      <ServiceHeader />
      <ServiceSection />
      <Footer />
    </div>
  );
}

export default Service;
