import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reviewssection.css";
import { FaStar } from "react-icons/fa";

// Custom Next Arrow component
const NextArrow = ({ onClick }) => (
  <div className="arrow next" onClick={onClick}>
    &#8250;
  </div>
);

// Custom Previous Arrow component
const PrevArrow = ({ onClick }) => (
  <div className="arrow prev" onClick={onClick}>
    &#8249;
  </div>
);

function Reviews() {
  const settings = {
    centerMode: true,
    centerPadding: "0",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const reviews = [
    {
      name: "John Doe",
      rating: 3,
      text: "Amazing service! Highly recommended.",
    },
    {
      name: "Jane Smith",
      rating: 4,
      text: "Great experience, will come back again.",
    },
    { name: "Samuel Lee", rating: 5, text: "Professional and efficient." },
    {
      name: "Anna Kim",
      rating: 3,
      text: "Good, but there's room for improvement.",
    },
    { name: "Michael Brown", rating: 4, text: "Satisfied with the service." },
    {
      name: "Emily Davis",
      rating: 5,
      text: "Exceptional quality and customer care.",
    },
  ];

  return (
    <div>
      <div className="review-section">
        <h2 className="review-title">Our Satisfied Clients</h2>
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div className="slide" key={index}>
              <div className="review-card">
                <h3>{review.name}</h3>
                <div className="stars">
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      key={i}
                      color={i < review.rating ? "#ffb400" : "#e4e5e9"} // Golden if rating, else gray
                    />
                  ))}
                </div>
                <p>{review.text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Consultation Section */}
      <section className="pro-section">
        <div className="pro-background">
          <div className="pro-content">
            <h2>Our satisfied clients reflect our true value​</h2>
            <p>
              Contact us today. We promise top-quality design services for your
              project at an unbeatable price.
            </p>
            <a href="/contactus">
              <button className="contact-us-button2">Contact Us</button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Reviews;
