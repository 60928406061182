import React, { useEffect } from "react";
import "./CombinedSection.css";
import { FaStar } from "react-icons/fa";
import "@fortawesome/fontawesome-free/css/all.min.css";

function CombinedSection() {
  // Counter animation function
  function animateCounter(element, start, end, duration) {
    let startTime = null;

    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const current = Math.min(
        Math.ceil((progress / duration) * (end - start) + start),
        end
      );
      element.textContent = current.toLocaleString();
      if (current < end) {
        requestAnimationFrame(step);
      }
    }

    requestAnimationFrame(step);
  }

  useEffect(() => {
    // Initialize counters when they come into view
    const counters = document.querySelectorAll(".counter-number");
    const counterSection = document.querySelector(".counter-section");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          counters.forEach((counter) => {
            const endValue = parseInt(counter.getAttribute("data-count"), 10);
            animateCounter(counter, 0, endValue, 2000); // 2000ms for 2 seconds duration
          });
          observer.disconnect(); // Stop observing after animation
        }
      });
    });

    if (counterSection) {
      observer.observe(counterSection);
    }
  }, []);

  // Former Clients Reviews data
  const reviews = [
    { name: 'John Doe', rating: 4, text: 'The team provided excellent service and the results were outstanding. Highly recommended!' },
    { name: 'Jane Smith', rating: 5, text: 'Professional and reliable, they exceeded our expectations with their detailed work.' },
    { name: 'Emily Johnson', rating: 5, text: 'Fantastic experience from start to finish. The consultation was informative and the design was perfect.' }
  ];

  return (
    <div>
      {/* Info Section */}
      <section className="info-section">
        <div className="info-content">
          <div className="image-content">
            <div className="image-overlay"></div>
            <img src="Images/welcome.png" alt="Structural Design" loading="eager" />
          </div>
          <div className="text-content">
            <h2>Welcome</h2>
            <p>
              We provide structural designs, structural calculations, and comprehensive drawings for a wide range of projects. Whether you need solutions for single beams or complex structures involving wall removals, we have the expertise to deliver precise and reliable results. From residential renovations to large-scale commercial projects, our team ensures that every structural element is carefully analyzed and engineered to meet the highest standards of safety and functionality. Our commitment is to provide innovative and cost-effective solutions tailored to your specific needs, ensuring the success of your project from concept to completion.
            </p>
            <a href="/contactus">
              <button className="contact-us-button">Contact Us</button>
            </a>
          </div>
        </div>
      </section>

      {/* Our Services Section */}
      <section className="services-section">
        <div className="services-grid">
          {/* Large Image on the Left */}
          <div className="service-item large">
            <img src="Images/beamdesign.jpg" alt="Beamdesign" loading="eager" />
            <div className="overlay"></div>
            <div className="service-text">Beam Designs</div>
          </div>
          {/* Container for Four Smaller Images in the Middle */}
          <div className="small-container">
            <div className="service-item small">
              <img src="Images/loftconversion.jpg" alt="loftdesign" loading="eager" />
              <div className="overlay"></div>
              <div className="service-text">Loft Conversion Design</div>
            </div>
            <div className="service-item small">
              <img src="Images/foundation.jpg" alt="foundationdesign" loading="eager" />
              <div className="overlay"></div>
              <div className="service-text">Foundation Design</div>
            </div>
            <div className="service-item small">
              <img src="Images/timber.png" alt="timberdesign" loading="eager" />
              <div className="overlay"></div>
              <div className="service-text">Timber Engineering</div>
            </div>
            <div className="service-item small">
              <img src="Images/truss.png" alt="Roof Design" loading="eager" />
              <div className="overlay"></div>
              <div className="service-text">Roofs and Truss Design</div>
            </div>
          </div>
          {/* Large Image on the Right */}
          <div className="service-item large">
            <img src="Images/consultancy.jpg" alt="Consultancy Services" loading="eager" />
            <div className="overlay"></div>
            <div className="service-text">Consultancy Services</div>
          </div>
        </div>
      </section>

      {/* Former Clients Reviews Section */}
      <section className="reviews-section">
        <h2>Former Clients Reviews</h2>
        <div className="reviews-cards">
          {reviews.map((review, index) => (
            <div className="review-card" key={index}>
              <div className="review-text">
                <h3>{review.name}</h3>
                <div className="stars">
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      key={i}
                      color={i < review.rating ? "#ffb400" : "#e4e5e9"} // Golden if rating, else gray
                      className="star"
                    />
                  ))}
                </div>
                <p>{review.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Structural Section */}
      <section className="Structural-section">
        <div className="Structural-background">
          <div className="Structural-content">
            <h5>Increasing the longevity of buildings and structures</h5>
            <p>Structural Repairs are a leading global specialist</p>
          </div>
        </div>
      </section>

      {/* Some Recent Projects Section */}
      <section className="projects-section">
        <h2>Some Recent Projects</h2>
        <div className="projects-gallery">
          <div className="project-item">
            <img src="Images/house1.jpg" alt="Project 1" loading="eager" />
          </div>
          <div className="project-item">
            <img src="Images/house2.jpg" alt="Project 2" loading="eager" />
          </div>
          <div className="project-item">
            <img src="Images/house3.jpg" alt="Project 3" loading="eager" />
          </div>
          <div className="project-item">
            <img src="Images/house4.jpg" alt="Project 4" loading="eager" />
          </div>
          <div className="project-item">
            <img src="Images/house5.jpg" alt="Project 5" loading="eager" />
          </div>
          <div className="project-item">
            <img src="Images/house6.jpg" alt="Project 6" loading="eager" />
          </div>
        </div>
      </section>

      {/* Stats Counter Section */}
      <section className="counter-section">
        <div className="counter-items">
          <div className="counter-item">
            <div className="counter-number" data-count="1010">
              1,010
            </div>
            <p>Former Clients</p>
          </div>
          <div className="counter-item">
            <div className="counter-number" data-count="1045">
              1,045
            </div>
            <p>Previous Projects</p>
          </div>
          <div className="counter-item">
            <div className="counter-number" data-count="0">
              0
            </div>
            <p>Structural Complaints</p>
          </div>
          <div className="counter-item">
            <div className="counter-number" data-count="995">
              995
            </div>
            <p>Satisfied Clients</p>
          </div>
        </div>
      </section>

      {/* Consultation Section */}
      <section className="consultation-section">
        <div className="consultation-background">
          <div className="consultation-content">
            <h2>Get Your Free Consultation</h2>
            <p>
              Give us a call for consultation or advice on your projects. We are
              happy to offer advice on anything from single wall removals to
              Building Regulation Advice or Pre-Planning Application.
            </p>
            <a href="/contactus">
              <button className="contact-us-button">Contact Us</button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CombinedSection;
