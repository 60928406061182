import React from "react";
import "./ServiceHeader.css";

function ServiceHeader() {
  return (
    <header className="Service-header">
      <div className="service-static-background">
        <div className="service-content">
          <h1>Our Services</h1>
          <p>We Provide the Best Service in Industry​</p>
        </div>
      </div>
    </header>
  );
}
export default ServiceHeader;
