import React, { useEffect } from "react";
import "./Header.css";

function Header() {
  useEffect(() => {
    const handleScroll = () => {
      const parallax = document.querySelector('.static-background');
      const scrollPosition = window.scrollY;
      parallax.style.backgroundPositionY = `${scrollPosition * 0}px`;
    };
   
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="App-header">
      <div className="static-background">
        <div className="overlay"></div>
        <div className="header-content">
          <h1>
            <span className="welcome-text">Welcome To</span> <br />
            <span className="highlight">Creating Space for You</span>
          </h1>
          <div className="text-and-button">
            <p>
              We provide structural designs, structural calculations & drawings
              for single to complex beams for wall removals, to larger complex
              projects. Trusted to ensure the longevity of buildings and
              structures.
            </p>
            <a href="about">
            <button className="learn-more-button">Learn More</button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
