import React, { useState } from "react";
import "./contactform.css";

function Contactus() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false); // Add state to track submission

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("http://localhost:5000/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        setSubmitted(true); // Set submitted to true
      })
      .catch((error) => {
        alert("Failed to send message. Try again later.");
      });
  };

  return (
    <div>
      {/* Contact Section */}
      <section className="contact-us">
        <div className="contact-container">
          {/* Contact Information */}
          <div className="contact-info">
            <h2>Contact Info</h2>
            <div className="contact-info-item">
              <i className="fas fa-phone"></i> <b>Phone:</b> 01753 709 770
            </div>
            <div className="contact-info-item">
              <i className="fas fa-phone"></i> <b>Tel:</b> 07748 716 893
            </div>
            <div className="contact-info-item">
              <i className="fas fa-envelope"></i> <b>Email:</b>{" "}
              info@creatingspace4u.co.uk
            </div>

            <div className="contact-info-item">
              <i className="fas fa-clock"></i> <b>Business Hours:</b> 9:00 AM -
              5:00 PM
            </div>
            <div className="contact-info-item">
              <i className="fas fa-map-marker-alt"></i> <b>Address:</b> Regus
              House, 268 Bath Road, Slough, Berkshire, SL1 4DX, UK
            </div>
            {/* Live Map */}
            <div className="map-container">
              <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d79444.89233456788!2d-0.7143291283379244!3d51.519583937789506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x487664cc12c0361d%3A0xf3c36628b2dae0ef!2s268%20Bath%20Rd%2C%20Slough%20SL1%204DX%2C%20United%20Kingdom!3m2!1d51.5196128!2d-0.6319289!5e0!3m2!1sen!2s!4v1724936585157!5m2!1sen!2s"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          {/* Contact Form */}
          <div
            className={`contact-form-wrapper ${submitted ? "submitted" : ""}`}
          >
            <div className="form-overlay"></div>
            {!submitted ? (
              <div className="contact-form">
                <h2>Send Us A Message</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">
                      <i className="fas fa-user"></i> Name:
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      <i className="fas fa-envelope"></i> Email:
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">
                      <i className="fas fa-phone"></i> Phone:
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="+44" // UK country code
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">
                      <i className="fas fa-comment"></i> Message:
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="5"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="send-button">
                    Send Message
                  </button>
                </form>
              </div>
            ) : (
              <div className="thank-you-message">
                <h2>Thank You!</h2>
                <p>
                  Thank you for contacting us. We will get back to you soon.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contactus;
