import React from "react";
import "./servicesection.css";

function ServiceSec() {
  return (
    <div>
      <section className="our-services">
        <div className="services-header">
          <h2>Our Services</h2>
          <p>
            Structural Designs | Structural Calculations | Structural Drawings
          </p>
        </div>
        <div className="services-intro">
          <p>
            From the removal of a simple wall in your house to building a new
            extension,  Creating Space for You provides excellent services at
            extremely competitive prices. Our success comes from our guiding
            principles. These include:
          </p>
        </div>
        <div className="services-principles">
          <div className="principle">
            <div className="principle-icon">01.</div>
            <div className="principle-content">
              <h3>Meticulous Planning</h3>
              <p>
                We undertake site visits to understand your requirements and to
                take measurements.
              </p>
            </div>
          </div>
          <div className="principle">
            <div className="principle-icon">02.</div>
            <div className="principle-content">
              <h3>Completion On Time</h3>
              <p>
                We deliver an effective, economic design in a quick turnaround
                time.
              </p>
            </div>
          </div>
          <div className="principle">
            <div className="principle-icon">03.</div>
            <div className="principle-content">
              <h3>Perfect Execution</h3>
              <p>
                We work with your appointed Builders during construction to
                ensure your requirements are met.
              </p>
            </div>
          </div>
          <div className="principle">
            <div className="principle-icon">04.</div>
            <div className="principle-content">
              <h3>Affordable Prices</h3>
              <p>
                We are not VAT registered, so the price we quote to you is the
                total cost, with quality guaranteed.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Services Section */}
<section className="services-section-structural">
  <div className="services-content-structural">
    <div className="services-list-structural">
      <h2>
        <u>Our Services</u>
      </h2>
      <p>
        We undertake structural design and calculations in Steel, Timber,
        Masonry, and other innovative building materials. Our work
        includes:
      </p>
      <div className="services-items-structural">
        <div className="service-item-structural">
          <i className="fas fa-square fa-icon-structural"></i>
          <div>
            <h3>Beam Designs</h3>
            <p>Single Beams and Complex Beam structures</p>
          </div>
        </div>
        <div className="service-item-structural">
          <i className="fas fa-home fa-icon-structural"></i>
          <div>
            <h3>Loft Conversion Design</h3>
            <p>Structural design and Calculation Reports</p>
          </div>
        </div>
        <div className="service-item-structural">
          <i className="fas fa-building fa-icon-structural"></i>
          <div>
            <h3>Foundation Design</h3>
            <p>Engineered foundations including Raft foundation</p>
          </div>
        </div>
        <div className="service-item-structural">
          <i className="fas fa-house-damage fa-icon-structural"></i>
          <div>
            <h3>Roofs and Truss Design</h3>
            <p>Roof Trusses Design including Rafters, Purlins etc.</p>
          </div>
        </div>
        <div className="service-item-structural">
          <i className="fas fa-tree fa-icon-structural"></i>
          <div>
            <h3>Timber Engineering</h3>
            <p>Timber frame design including Oak frames</p>
          </div>
        </div>
        <div className="service-item-structural">
          <i className="fas fa-handshake fa-icon-structural"></i>
          <div>
            <h3>Consultancy Services</h3>
            <p>
              Structural consultancy services to individuals and companies
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="services-image-structural">
      <img src="Images/services.png" alt="Service illustration" loading="eager|lazy" />
    </div>
  </div>
</section>


      {/* Consultation Section */}
      <section className="About-section">
        <div className="About-background">
          <div className="About-content">
            <h2>We Provide the Best Service in Industry​</h2>
            <p>
              Get in touch today. We guarantee you a high-quality design
              services for your project at a very affordable fee.
            </p>
            <a href="/contactus">
            <button className="contact-us-button1">Contact Us</button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceSec;
