import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import Services from './components/Services/Service';
import Project from './components/Projects/Project';
import Reviews from './components/Reviews/review';
import Contact from './components/ContactUs/contactus';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/project" element={<Project />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contactus" element={<Contact />} />

      </Routes>
    </Router>
  );
}

export default App;
