import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './gallery.css';

// Custom Next Arrow component
const NextArrow = ({ onClick }) => (
  <div className="arrow next" onClick={onClick}>
    &#8250;
  </div>
);

// Custom Previous Arrow component
const PrevArrow = ({ onClick }) => (
  <div className="arrow prev" onClick={onClick}>
    &#8249;
  </div>
);

function GallerySec() {
  const settings = {
    centerMode: true,
    centerPadding: '0', // Adjust padding as needed
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="gallery-section">
        <h2 className="gallery-title">Our Previous Projects</h2>
        <Slider {...settings}>
          <div className="slide">
            <img src="Images/house1.jpg" alt="Project 1" loading="eager|lazy" />
          </div>
          <div className="slide">
            <img src="Images/house2.jpg" alt="Project 2" loading="eager|lazy" />
          </div>
          <div className="slide">
            <img src="Images/house3.jpg" alt="Project 3" loading="eager|lazy" />
          </div>
          <div className="slide">
            <img src="Images/house4.jpg" alt="Project 4" loading="eager|lazy" />
          </div>
          <div className="slide">
            <img src="Images/house5.jpg" alt="Project 5" loading="eager|lazy" />
          </div>
          <div className="slide">
            <img src="Images/house6.jpg" alt="Project 6" loading="eager|lazy" />
          </div>
        </Slider>
      </div>
      
      {/* Consultation Section - moved outside of the gallery-section div */}
      <section className="pro-section">
        <div className="pro-background">
          <div className="pro-content">
            <h2>We Provide the Best Service in the Industry​</h2>
            <p>
              Get in touch today. We guarantee you high-quality design
              services for your project at a very affordable fee.
            </p>
            <a href='/contactus'>
            <button className="contact-us-button2">Contact Us</button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GallerySec;
