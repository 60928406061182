import React from 'react';
import './AboutUsHeader.css';

function AboutUsHeader() {
  return (
    <header className="App-header1">
      <div className="static-background1">
        <div className="header-content1">
          <h1>About Us</h1>
          <p>We are a team of professionally qualified and insured structural engineers</p>
        </div>
      </div>
    </header>
  );
}

export default AboutUsHeader;
