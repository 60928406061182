import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info">
          <img src="logof.png" alt="Company Logo" className="logo" />
          <p>Broad Vision Honest Service Great Value</p>
          <div className="social-icons">
            <a href="/contactus">
              <i className="fab fa-google"></i>
            </a>
            <a href="#">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h4>Our Company</h4>
          <ul>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/Project">Past Projects</a>
            </li>
            <li>
              <a href="/reviews">Reviews</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Our Services</h4>
          <ul>
            <li>
              <a href="/services">Beam Designs</a>
            </li>
            <li>
              <a href="/services">Loft Conversion Design</a>
            </li>
            <li>
              <a href="/services">Foundation Designs</a>
            </li>
            <li>
              <a href="/services">Timber Engineering</a>
            </li>
            <li>
              <a href="/services">Roofs and Truss Design</a>
            </li>
            <li>
              <a href="/services">Consultancy Services</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contact Details</h4>
          <ul>
          <li>
              <p>Regus House, 268 Bath Road, Slough, Berkshire, SL1 4DX, United Kingdom</p>
            </li>
            <li>01753 709 770</li>
            <li>07748 716 893</li>
            <li>
              <a href="mailto:info@creatingspace4u.co.uk">info@creatingspace4u.co.uk</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; 2024 Creating Space for You - All Rights Reserved.
        </p>
        <p>Powered by: <a href="https://solworx.co.uk">Solworx</a></p>
      </div>
    </footer>
  );
};

export default Footer;
