import React, { useState } from "react";
import "./Navbar.css";

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <a href="/">
        <img src="logo.png" alt="Creating Space for You" />
        </a>
      </div>
      <button
        className={`hamburger ${isNavOpen ? "hidden" : ""}`}
        aria-label="Toggle menu"
        onClick={toggleNav}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <ul className={`nav-links ${isNavOpen ? "active" : ""}`}>
        <button
          className="close-btn"
          aria-label="Close menu"
          onClick={toggleNav}
        >
          &times;
        </button>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about">About Us</a>
        </li>
        <li>
          <a href="/Project">Projects</a>
        </li>
        <li>
          <a href="/Services">Services</a>
        </li>
        <li>
          <a href="/reviews">Reviews</a>
        </li>
        <li>
          <a href="/contactus">Contact Us</a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
