import React from "react";
import Navbar from '../Navbar';
import ContactHeader from "./contactheader/contactheader";
import Contactform from "./contactform/contactform";

import Footer from "../Footer"; 

function Service() {
  return (
    <div>
      <Navbar />
      <ContactHeader />
      <Contactform/>
      <Footer />
    </div>
  );
}

export default Service;
