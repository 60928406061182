import React from "react";
import "./reviewheader.css";

function RerviewHeader() {
  return (
    <header className="review-header">
      <div className="review-static-background">
        <div className="review-content">
          <h1>Reviews</h1>
          <p>We Provide the Best Service in Industry​</p>
        </div>
      </div>
    </header>
  );
}
export default RerviewHeader;
