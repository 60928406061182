import React from "react";
import "./AboutSec.css";

function AboutSec() {
  return (
    <div>
      {/* About Us Section */}
      <section className="about-sec">
        <div className="about-sec-content">
          <div className="about-image">
            <img src="Images/beams.jpg" alt="About Us" loading="eager|lazy" />
          </div>
          <div className="about-text">
            <h2>Our Story</h2>
            <p>
              We are a team of professionally qualified and insured structural
              engineers with over 17 years of experience in the construction
              industry. Family-owned consultancy, based in Tadpole Garden
              Village, Swindon. We operate across the South East and South West
              of England and Wales with offices in Bristol, Slough, Swindon,
              Cardiff, and Swansea.
              <br />
              <br />
              Kade Orankan (Structural Engineer & Managing Director) started off
              as a General Handyman and Labourer with Alfred McAlpine
              Construction before going back to university for a second degree
              in Civil Engineering at the University of West of England,
              Bristol, finishing with First Class Honours in 2012. Stints with
              several Design Consultancies and Construction firms followed,
              before Creating Space for You was founded in 2016.
            </p>
            <ul className="features-list">
              <li>
                <i className="fas fa-check-circle"></i> Professional Specialist
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Brilliant Ideas
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Accurate and Precise
                Calculations
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Quick 24/7 Assistance
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* What We Offer Section */}
      <section className="what-we-offer">
        <h2>What We Offer</h2>
        <div className="offer-cards">
          <div className="offer-card">
            <img src="Images/engineer.jpg" alt="Service 1" loading="eager|lazy"  />{" "}
            {/* Replace with actual image path */}
            <div className="offer-text">
              <h3>Expert Consultation</h3>
              <p>Get expert advice and solutions tailored to your needs.</p>
              <button className="offer-btn">Learn More</button>
            </div>
          </div>
          <div className="offer-card">
            <img src="Images/precisionengineering.jpg" alt="Service 2" loading="eager|lazy" />{" "}
            {/* Replace with actual image path */}
            <div className="offer-text">
              <h3>Precision Engineering</h3>
              <p>
                Top-notch engineering services with meticulous attention to
                detail.
              </p>
              <button className="offer-btn">Learn More</button>
            </div>
          </div>
          <div className="offer-card">
            <img src="Images/support.png" alt="Service 3" loading="eager|lazy" />{" "}
            {/* Replace with actual image path */}
            <div className="offer-text">
              <h3>24/7 Support</h3>
              <p>Round-the-clock support for all your structural needs.</p>
              <button className="offer-btn">Learn More</button>
            </div>
          </div>
        </div>
      </section>

      {/* Our Vision Section */}
      <section className="our-vision">
        <h2>Our Vision</h2>
        <p>
          At Creating Space for You, our vision is to lead the industry in
          innovative structural design and consulting. We aim to deliver
          exceptional service, harnessing advanced technology and sustainable
          practices to create structures that stand the test of time. Our goal
          is to build a future where engineering excellence meets environmental
          responsibility, ensuring safety, durability, and integrity in every
          project.
        </p>
      </section>

      {/* Consultation Section */}
      <section className="About-section">
        <div className="About-background">
          <div className="About-content">
            <h2>We Provide the Best Service in Industry​</h2>
            <p>
              Get in touch today. We guarantee you a high-quality design
              services for your project at a very affordable fee.
            </p>
            <a href="/contactus">
            <button className="contact-us-button1">Contact Us</button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutSec;
